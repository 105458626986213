<template>
  <v-card>
    <div class="d-flex flex-column align-center justify-between pb-5">
      <v-img
        :src="
          agency.bgImage ? agency.bgImage : require('@/assets/background0.png')
        "
        width="100%"
        style="flex-grow: 1"
        :aspect-ratio="$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455"
        class="rounded-lg rounded-b-0 position-absolute"
      />
      <!-- <v-tooltip bottom v-if="getCompletedInfo">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="red"
            v-text="'mdi-alert'"
            v-bind="attrs"
            v-on="on"
            style="position:absolute; right: 10px; top: 10px;"
            @click="goToSetting"
          />
        </template>
        <span>Incomplete profile: Missing {{ getCompletedInfo }}</span>
      </v-tooltip> -->
      <agency-avatar
        :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
        class="mb-1 mt-10"
        :name="agency.name"
      />
      <span
        class="primary-color app-bold-font font-18 text-center mt-5 px-2 url-button"
        @click="goToProfile"
        >{{ agency.name }}</span
      >
      <span class="primary-color app-medium-font font-12 text-center px-2 mt-3"
        ><v-icon color="#0A5B8A" v-text="'mdi-map-marker-outline'" size="18" />
        {{ agency.address }},
        {{ agency.address2 ? agency.address2 + ", " : "" }} {{ agency.city }}
      </span>
      <div class="mt-2">
        <!-- <span
          class="px-2 mt-2 gray-font-color font-12"
          v-if="agency.volunteered_hours"
          ><v-icon
            v-text="'mdi-clock-outline'"
            color="grey"
            size="17"
            class="mr-1"
          />{{ Math.round(agency.volunteered_hours / 60) }} hours</span
        > -->
        <span class="followers px-2 mt-2" @click="followers"
          ><v-icon
            v-text="'mdi-eye-outline'"
            color="#0A5B8A"
            size="18"
            class="mr-1"
          />{{
            agency.followers && agency.followers.length ? agency.followers.length : 0
          }}
          Following</span
        >
      </div>
    </div>
  </v-card>
</template>
<script>
import AgencyAvatar from "./AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  props: {
    agency: Object,
    onClicked: Function
  },
  computed: {
    getCompletedInfo() {
      if (!this.agency._id) return "";
      var str = "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");
      if (!this.agency.phone_verified)
        str ? (str += ", Phone not verified") : (str = "Phone not verified");
      if (!this.agency.email_verified || !this.agency.email_verified)
        str ? (str += ", Email not verified") : (str = "Email not verified");

      return str;
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.agency._id }
      });
    },
    goToSetting() {
      if (!this.agency.email_verified || !this.agency.phone_verified) {
        this.$router.push({ name: "settings" });
      } else {
        this.$router.push({
          name: "agency-profile-about",
          params: { id: this.agency._id }
        });
      }
    },
    followers() {
      this.$router.push({
        name: "agencies",
        params: { id: this.agency._id }
      });
    }
  }
};
</script>
<style scoped>
.followers{
  cursor: pointer;
  color: #0A5B8A !important;
  font-weight: 500;
  font-size: 13px;
}
</style>
